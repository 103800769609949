import { Outlet } from '@remix-run/react';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { PublicHomeLayout } from '../components/PublicHome/Layout';
import { UserContextProvider } from '../components/UserContext';
import { useTitle } from '../hooks/useTitle';
import { makeTitle } from '../utils/common';

export const clientLoader = async () => {
  return null;
};

export function Component() {
  useTitle(makeTitle('Subscription Cancel'));

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <PublicHomeLayout>
        <div className='flex-1 overflow-y-auto scrollbar'>
          <Outlet />
        </div>
      </PublicHomeLayout>

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
